import {
    SimpleForm,
    DateInput,
    Edit,
    Toolbar,
    SaveButton,
    useRecordContext,
    Button,
    useRedirect,
    BooleanInput,
    minValue,
    RadioButtonGroupInput,
    NumberInput,
    FormDataConsumer,
    ArrayInput,
    SimpleFormIterator,
    SelectInput,
    useInput,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { Tooltip } from '@mui/material'
import requestHandler from 'utils/requestHandler'
import { getCookie } from 'cookies'
import { daredropApiUrl } from 'constants/app'
import moment from 'moment'
import { resourcesIds } from 'resources'
import { authNames } from 'constants/cookies'
import equals from 'ramda/src/equals'
import React, { ChangeEvent } from 'react'
import { useHelperText } from 'hooks/useHelperText'
import { pointsToDollarsHandler } from 'utils/pointsToDollars'
// @ts-ignore
import { DarePlatform, DropEvent, TwitchUser } from 'types/index.d.ts'
import styles from './styles/manageCampaign.module.scss'
import { ProductResolver } from '../../utils/drops/ProductResolver'
import { AdminAsyncFilterAutocomplete } from '../../components/AdminAsyncFilterAutocomplete'
import { DaredropApi } from '../../utils/daredrop-api'

enum PointsCalculationMethod {
    DEFAULT = 'default',
    CPVH = 'cpvh',
}

const ManageCampaign = () => (
    <Edit
        redirect={`/${resourcesIds.CAMPAIGNS_RESOURCE_ID}`}
        mutationMode="pessimistic"
    >
        <SimpleForm toolbar={<SubmitToolbar />}>
            <CampaignManagementForm />
        </SimpleForm>
    </Edit>
)

const SubmitToolbar = () => (
    <Toolbar>
        <SaveButton />
    </Toolbar>
)

const RewardScalabilityInput = () => {
    const dropEvent = useRecordContext<DropEvent>()
    const products = [
        ...new Map(
            dropEvent.dares.map((dare) => [dare.product.id, dare.product])
        ).values(),
    ]
    const isRewardScalabilityEnabled = useInput({
        source: 'rewardScalability.enabled',
    }).field.value

    const scalabilityRewards = (useInput({
        source: 'rewardScalability.rewards',
    }).field.value || []) as { rewardId: string }[]

    return (
        <div>
            <BooleanInput
                label="Reward scalability"
                source="rewardScalability.enabled"
            />
            {isRewardScalabilityEnabled && (
                <ArrayInput
                    disabled={!isRewardScalabilityEnabled}
                    source="rewardScalability.rewards"
                    label="Reward scalability"
                >
                    <SimpleFormIterator
                        disableReordering
                        getItemLabel={() => ''}
                    >
                        <SelectInput
                            helperText={false}
                            label="Reward"
                            source="rewardId"
                            choices={products.map((product) => {
                                const productResolver = new ProductResolver(
                                    product
                                )
                                const isUsed = scalabilityRewards.some(
                                    (reward) => reward.rewardId === product.id
                                )
                                return {
                                    id: productResolver.getId(),
                                    name: productResolver.getName(),
                                    disabled: isUsed,
                                }
                            })}
                        />
                        <ArrayInput source="increments" label="Increments">
                            <SimpleFormIterator
                                className={styles.rewardScalabilityFormIterator}
                                disableReordering
                                getItemLabel={() => ''}
                            >
                                <SelectInput
                                    label="Platform"
                                    source="platform"
                                    helperText={false}
                                    choices={[
                                        {
                                            id: DarePlatform.Twitch,
                                            name: 'Twitch',
                                        },
                                        {
                                            id: DarePlatform.YouTube,
                                            name: 'YouTube',
                                        },
                                        {
                                            id: DarePlatform.TikTok,
                                            name: 'TikTok',
                                        },
                                    ]}
                                />
                                <NumberInput
                                    label="Value"
                                    source="value"
                                    helperText="CCV/AVG VIEWS in last 30 days"
                                />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </SimpleFormIterator>
                </ArrayInput>
            )}
        </div>
    )
}

const CampaignManagementForm = () => {
    const redirect = useRedirect()
    const activeKey = 'ACTIVE'
    const dropEvent = useRecordContext<DropEvent>()
    const {
        approved,
        status,
        subscriptionStatus,
        budget: { type: budgetType },
        id,
        brand,
    } = dropEvent

    const managementButtons = [
        {
            label: 'Enable',
            disabled: !approved || status === activeKey,
            tooltip: 'Campaign already enabled',
            endpointId: 'CHANGE_CAMPAIGN_STATUS',
            payload: {
                action: 'ENABLE',
                dropEventId: id,
                brandId: brand.id,
            },
        },
        {
            label: 'Disable',
            disabled: !approved || status !== activeKey,
            tooltip: 'Campaign already disabled',
            endpointId: 'CHANGE_CAMPAIGN_STATUS',
            payload: {
                action: 'DISABLE',
                dropEventId: id,
                brandId: brand.id,
            },
        },
        {
            label: 'Reset dares',
            disabled: false,
            tooltip: 'Campaign already disabled',
            endpointId: 'RESET_CAMPAIGN_DARES',
            payload: {
                id,
            },
        },
    ]

    return (
        <>
            <div style={{ display: 'flex' }}>
                {managementButtons.map(
                    ({ label, disabled, tooltip, endpointId, payload }) => (
                        <Tooltip
                            key={label}
                            title={disabled ? tooltip : ''}
                            placement="top"
                        >
                            <div>
                                <Button
                                    label={label}
                                    size="large"
                                    color="primary"
                                    disabled={disabled}
                                    onClick={async () => {
                                        const res = await requestHandler<{
                                            statusCode: number
                                            body?: Record<string, any>
                                            error?: Record<string, any>
                                        }>({
                                            url: daredropApiUrl,
                                            method: 'POST',
                                            body: {
                                                endpointId,
                                                payload,
                                                authentication: getCookie(
                                                    authNames.ADMIN_APP_TOKEN
                                                ),
                                            },
                                            headers: {},
                                        })
                                        const { statusCode } = res

                                        if (statusCode === 200) {
                                            redirect(
                                                `/${resourcesIds.CAMPAIGNS_RESOURCE_ID}`
                                            )
                                        }
                                    }}
                                />
                            </div>
                        </Tooltip>
                    )
                )}
            </div>
            {equals(approved, false) && (
                <BooleanInput label="Approve" source="approved" />
            )}
            {subscriptionStatus !== activeKey && budgetType === 'CAMPAIGN' && (
                <BooleanInput
                    label="Activate Invoice"
                    source="activateInvoice"
                />
            )}
            <BooleanInput
                label="Campaign should award points"
                source="awardPoints"
            />
            <PointsSection dropEvent={dropEvent} />
            <BooleanInput
                label="Disable 'User has game' behavior"
                source="disableUserHasGame"
            />
            <DateInput
                label="Campaign expiration date"
                source="expirationDate"
                validate={minValue(moment().add(1, 'day').format('YYYY-MM-DD'))}
            />
            <RewardScalabilityInput />
        </>
    )
}

type PointsSectionParams = {
    dropEvent: DropEvent
}
const PointsSection = ({ dropEvent }: PointsSectionParams) => {
    const { helperText, setHelperText } = useHelperText(
        pointsToDollarsHandler,
        dropEvent.pointsLimit ?? 0
    )
    const { setValue } = useFormContext()

    return (
        <>
            {/* @ts-ignore */}
            <FormDataConsumer<{
                awardPoints: boolean
                pointsCalculationMethod: PointsCalculationMethod
            }>>
                {({ formData }) => {
                    return (
                        formData.awardPoints && (
                            <>
                                <NumberInput
                                    label="Campaign points limit"
                                    source="pointsLimit"
                                    onChange={setHelperText}
                                    helperText={helperText}
                                    validate={minValue(1)}
                                    format={(val) => Math.floor(val)}
                                    defaultValue={0}
                                />
                                <RadioButtonGroupInput
                                    label="Points calculation method"
                                    source="pointsCalculationMethod"
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>
                                    ) => {
                                        if (
                                            e.target?.value !==
                                            PointsCalculationMethod.CPVH
                                        ) {
                                            setValue(
                                                'costPerViewHourPoints',
                                                undefined
                                            )
                                        }
                                        if (
                                            e.target?.value ===
                                            PointsCalculationMethod.CPVH
                                        ) {
                                            setValue('costPerViewHourPoints', 0)
                                        }
                                    }}
                                    choices={[
                                        {
                                            id: PointsCalculationMethod.DEFAULT,
                                            name: 'Default',
                                        },
                                        {
                                            id: PointsCalculationMethod.CPVH,
                                            name: 'CPVH',
                                        },
                                    ]}
                                />
                                {formData.pointsCalculationMethod ===
                                    PointsCalculationMethod.CPVH && (
                                    <NumberInput
                                        label="Cost per view hour (in points)"
                                        source="costPerViewHourPoints"
                                    />
                                )}
                            </>
                        )
                    )
                }}
            </FormDataConsumer>
            <AdminAsyncFilterAutocomplete
                label="Featured creator"
                optionLabel="mnemonicId"
                source="featuredCreator"
                multiple={false}
                asyncOptionsFn={async (searchPhrase) => {
                    const twitchUsers = await DaredropApi.searchTwitchUsers(
                        searchPhrase
                    )
                    return twitchUsers.map(
                        ({
                            id: platformId,
                            display_name,
                            broadcaster_login,
                        }: TwitchUser) => ({
                            mnemonicId: broadcaster_login,
                            displayName: display_name,
                            platformId,
                            platform: 'twitch',
                        })
                    )
                }}
            />
        </>
    )
}

export default ManageCampaign
